import React from "react"
import { graphql } from 'gatsby'
import { Grid, Margin, } from '@components/atoms'
import { GridItem, Hero } from '@components/molecules'
import { TransitionMask } from '@components/organisms'
import { PageProps } from '@types'
import { getCdnPath } from '@utils'
import { Helmet } from 'react-helmet'


const AboutPage = ({data: { 
  sanityPage: {
    heroType,
    heroHeight,
    heroTextConditional,
    heroImageConditional,
    heroVideoConditional,
    heroFX,
    heroParallaxConditional,
    gridItems,
    gridSnap,
    inverted,
    shareImage,
    description,
    title,
  }
}}: PageProps) => {
  const parsedTitle = `TWA: ${title}`
  const meta = [
    { property: "og:title", content: parsedTitle },
    { name: "twitter:title", content: parsedTitle },
    { name: "description", content: description },
    { name: "twitter:description", content: description },
    { property: "og:description", content: description },
  ]
  if(shareImage) {
    meta.push({ property: "og:image", content:  getCdnPath(`${shareImage.asset.url}?w=640&fm=jpg`) })
    meta.push({ name: "twitter:image", content:  getCdnPath(`${shareImage.asset.url}?w=640&fm=jpg`) })
  }
  return (
    <>
      <Helmet {...{meta}} title={parsedTitle} >
        <title>{parsedTitle}</title>
      </Helmet>
      <Hero {...{ heroType, heroHeight, heroImageConditional, heroVideoConditional, heroParallaxConditional, heroTextConditional, heroFX }} />
      <Margin>
        <Grid {...{ gridSnap }} >
          {gridItems.filter(p => !!p).map((item) => (
            <GridItem key={item._key} {...item} />
          ))}
        </Grid>
      </Margin>

      <TransitionMask {...{ inverted }} />
    </>
  )
}


export const query = graphql`
  query AboutPageQuery {
    sanityPage(slug: {current: {eq: "about"}}) {
      ...pageFields
    }
  }
`

export default AboutPage
